    function filterCategories(cat){
      $(this).addClass('active');
      $('.slider-item').hide();

      $('#slider-'+ cat).css('display', 'block'); 
      $('#slider-'+ cat).get(0).slick.setPosition()
    }

$(document).ready(function(){

    $("#phone").mask("(99) 9999-9999?9");

    //alignFooter ();
    var currentSection = location.hash;
    var menuHeight = 100;
    var slickPanel = $('.slider-home-panel');
    
    //var slickEmpresa = $('.slider-empresa');

    $(".company a").click(function(){
      $("html, body").animate({ scrollTop: $('.empresa').offset().top }, 1000);
      $(".navbar-nav a").removeClass("active");
      $("#empresa-link").addClass('active');
    });

    $(".navbar-nav a").click(function(){
        var id= $(this).attr("id").replace("-link","");
        var magic = 0;
        if($(window).width() < 800){
            if(id == 'empresa'){
                magic = -3;
            }else{
                magic = 50;
            }
        }else{
            if(id == 'empresa'){
                magic = 0;   
            }else{
                magic = 100;   
            }
        }
      $("#navbarCollapse").attr("aria-expanded",false).removeClass("in");      
      $(".navbar-nav a").removeClass("active");
      $(this).addClass("active");
      $("html, body").animate({ scrollTop: $('#'+id).offset().top - magic }, 1000);
    });

    $('div.bgParallax').each(function(){
        var $obj = $(this);

      $(window).scroll(function() {
          var yPos = -($(window).scrollTop() / $obj.data('speed')); 

          var bgpos = '50% '+ yPos + 'px';

          $obj.css('background-position', bgpos );

      }); 
    }); 

    //quando troca a imagem do painel, anima os elementos de texto
    // slickEmpresa.on('afterChange', function(event, slick, currentSlide, nextSlide){

    //   $(slick.$slides.get(currentSlide)).find(".text").animate({
    //     opacity: 1
    //   },500, function(){
    //     $(slick.$slides.get(currentSlide)).find(".title").addClass('fadeInLeft');
    //     $(slick.$slides.get(currentSlide)).find(".subtitle").addClass('fadeInRight');

    //     $(slick.$slides.get(currentSlide)).find(".btn-blue").addClass('fadeIn');
    //   });
    // });

    /* END PANEL */


    $('button.navbar-toggle').click(function() {
      if($(this).attr('aria-expanded') == 'true'){        
        $(".navbar-header .bg-mobile").hide();        
      }else{        
        $(".navbar-header .bg-mobile").show();
      }
    });

    $('#sections').css('opacity', 0);

    setTimeout(function(){
      $('#sections').addClass('fadeInUp');
    }, 1700)
    
    if($(window).width() <= 990){
        $("#empresa-gal").remove();
        $('.slider-empresa').slick({
            dots: true,
            arrows: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1
        });
    }    

    

      var slider = $('#slick1');
      var allSlides = $('.slick-slide > div > div');
      
      var countTodos = $('#slider-todos').find('.item').length;
      $('#slider-todos').slick({
          rows: (countTodos > 4 ? 2 : 1),
          dots: true,
          arrows: true,
          infinite: true,
          speed: 300,
          slidesToShow: (countTodos > 4 && countTodos <= 6 ? 3 : 4),
          slidesToScroll: 4,
          responsive: [
              {
                  breakpoint: 768,
                  settings: {
                      rows: 1,
                      slidesToScroll: 3,
                      slidesToShow: 3,
                      arrows: false
                  }
              }
          ]
        });
        
        var countAreas = $('#slider-areas-gourmet').find('.item').length;
        //console.log('countAreas', countAreas)
        $('#slider-areas-gourmet').slick({
          rows: (countAreas > 4 ? 2 : 1),
          dots: true,
          arrows: true,
          infinite: true,
          speed: 300,
          slidesToShow: (countAreas > 4 && countAreas <= 6 ? 3 : 4),
          slidesToScroll: 4,
          responsive: [
              {
                  breakpoint: 768,
                  settings: {
                      rows: 1,
                      slidesToScroll: 3,
                      slidesToShow: 3,
                      arrows: false
                  }
              }
          ]
        });
        
        var countBanheiros = $('#slider-banheiros').find('.item').length;
        $('#slider-banheiros').slick({
          rows: (countBanheiros > 4 ? 2 : 1),
          dots: true,
          arrows: true,
          infinite: true,
          speed: 300,
          slidesToShow: (countBanheiros > 4 && countBanheiros <= 6 ? 3 : 4),
          slidesToScroll: 4,
          responsive: [
              {
                  breakpoint: 768,
                  settings: {
                      rows: 1,
                      slidesToScroll: 3,
                      slidesToShow: 3,
                      arrows: false
                  }
              }
          ]
        });

        var countChurrasqueiras = $('#slider-churrasqueiras').find('.item').length;
        $('#slider-churrasqueiras').slick({
          rows: (countChurrasqueiras > 4 ? 2 : 1),
          dots: true,
          arrows: true,
          infinite: true,
          speed: 300,
          slidesToShow: (countChurrasqueiras > 4 && countChurrasqueiras <= 6 ? 3 : 4),
          slidesToScroll: 4,
          responsive: [
              {
                  breakpoint: 768,
                  settings: {
                      rows: 1,
                      slidesToScroll: 3,
                      slidesToShow: 3,
                      arrows: false
                  }
              }
          ]
        });

        var countCozinhas = $('#slider-cozinhas').find('.item').length;
        $('#slider-cozinhas').slick({
          rows: (countCozinhas > 4 ? 2 : 1),
          dots: true,
          arrows: true,
          infinite: true,
          speed: 300,
          slidesToShow: (countCozinhas > 4 && countCozinhas <= 6 ? 3 : 4),
          slidesToScroll: 4,
          responsive: [
              {
                  breakpoint: 768,
                  settings: {
                      rows: 1,
                      slidesToScroll: 3,
                      slidesToShow: 3,
                      arrows: false
                  }
              }
          ]
        });

        var countDiversos = $('#slider-diversos').find('.item').length;
        $('#slider-diversos').slick({
          rows: (countDiversos > 4 ? 2 : 1),
          dots: true,
          arrows: true,
          infinite: true,
          speed: 300,
          slidesToShow: (countDiversos > 4 && countDiversos <= 6 ? 3 : 4),
          slidesToScroll: 4,
          responsive: [
              {
                  breakpoint: 768,
                  settings: {
                      rows: 1,
                      slidesToScroll: 3,
                      slidesToShow: 3,
                      arrows: false
                  }
              }
          ]
        });
        
        var countEscadas = $('#slider-escadas').find('.item').length;
        $('#slider-escadas').slick({
          rows: (countEscadas > 4 ? 2 : 1),
          dots: true,
          arrows: true,
          infinite: true,
          speed: 300,
          slidesToShow: (countEscadas > 4 && countEscadas <= 6 ? 3 : 4),
          slidesToScroll: 4,
          responsive: [
              {
                  breakpoint: 768,
                  settings: {
                      rows: 1,
                      slidesToScroll: 3,
                      slidesToShow: 3,
                      arrows: false
                  }
              }
          ]
        });

        var countHalls = $('#slider-halls').find('.item').length;
        $('#slider-halls').slick({
          rows: (countHalls > 4 ? 2 : 1),
          dots: true,
          arrows: true,
          infinite: true,
          speed: 300,
          slidesToShow: (countHalls > 4 && countHalls <= 6 ? 3 : 4),
          slidesToScroll: 4,
          responsive: [
              {
                  breakpoint: 768,
                  settings: {
                      rows: 1,
                      slidesToScroll: 3,
                      slidesToShow: 3,
                      arrows: false
                  }
              }
          ]
        });

        var countLareiras = $('#slider-lareiras').find('.item').length;
        $('#slider-lareiras').slick({
          rows: (countLareiras > 4 ? 2 : 1),
          dots: true,
          arrows: true,
          infinite: true,
          speed: 300,
          slidesToShow: (countLareiras > 4 && countLareiras <= 6 ? 3 : 4),
          slidesToScroll: 4,
          responsive: [
              {
                  breakpoint: 768,
                  settings: {
                      rows: 1,
                      slidesToScroll: 3,
                      slidesToShow: 3,
                      arrows: false
                  }
              }
          ]
        });

    $('.type').on('click', function(){
      $('.type').removeClass('active');
      $(this).addClass('active');
      $('.slider-item').hide();
      var cat = $(this).attr('id');
      $('#slider-'+ cat).css('display', 'block'); 
      $('#slider-'+ cat).get(0).slick.setPosition()
    });

    function alignFooter(){
      var docHeight = $(window).height();
      var footerHeight = $('#footer').outerHeight();
      var footerTop = $('#footer').position().top + footerHeight;
      if (footerTop < docHeight) {
        $('#footer').css('margin-top', 10 + (docHeight - footerTop) + 'px');
      }
    }

    // $(window).bind('scroll', function() {      
    //   var magicnumber = 0;
    //   if($(window).width() <= 991){
    //     magicnumber = -300;
    //   }
    //   if($(window).scrollTop() >= ($('.empresa').outerHeight() + parseInt($(".empresa").css('padding-top')) + parseInt($(".empresa").css('padding-bottom'))) - (window.innerHeight - magicnumber)) {          
    //       $(".navbar").addClass("show");
    //   }else{          
    //     $(".navbar").removeClass("show");          
    //   }
    // });    

    //if is home
    if(window.location.href.split('/')[3] == ""){
      if($(window).width() > 990){
        var waypoint = new Waypoint({
        element: document.getElementById('empresa-gal'),
        handler: function() {
          $('#empresa-gal').addClass('fadeInUp');          
        },
        offset: 500
      });
      }

      var waypoint = new Waypoint({
        element: document.getElementById('empresa-col-right'),
        handler: function() {
          $('#empresa-col-right').addClass('fadeInUp');
        },
        offset: 500
      });
    };

    $(window).bind('scroll', function() {       
      if($(this).scrollTop() + ($(window).width() > 750 ? 100 : 0) >=$('.empresa').position().top){          
          $(".navbar").addClass("show");
      }else{          
        $(".navbar").removeClass("show");          
      }      
    });

    setTimeout(function() {
      $("#preloader").animate({
        opacity: 0
      }, 400, function(){
        $(this).hide();
      });
    }, 400);

    $("img.lazy, div.lazy").lazyload({
        effect : "fadeIn"
    });

    // if(currentSection != ""){
    //     var offset = $(currentSection.replace('-link',"")).offset().top - menuHeight;
    //     $("html, body").animate({ scrollTop: offset }, 500);
    // }

    // $('.navbar-nav a').click(function(event){
    //     event.preventDefault();
    //     event.stopPropagation();

    //     var clickedSection = $(this).attr('id').replace('-link',"");

    //     var currentSection = location.href;
    //     if(currentSection.indexOf('blog') != -1){
    //         window.location = "/#" + clickedSection;
    //         return false;
    //     }

    //     if(clickedSection.indexOf('blog') != -1){
    //         window.location = clickedSection;
    //         return false;
    //     }

    //     setTimeout(function() {
    //         var offset = $("#" + clickedSection).offset().top - menuHeight;
    //         $("html, body").animate({ scrollTop: offset }, 500, function(){
    //             location.hash = clickedSection;
    //         });
    //     }, 300);

    // });

});
